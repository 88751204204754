<template>
  <div class="contents">
    <!-- 스크랩핑 수동실행 -->
    <div class="card shadow">
      <div class="card-body">
        <!-- 검색영역  -->
        <div class="credit-table-block pl20 pt20 pb20 mb20">
          <!-- 1 row -->
          <div class="tx_lt pb5 pt5">
            <base-select-box
                :class="selectBoxClass1"
                :items="selectBoxItems1"
                @toParent:selectValue="val => changeSelectBoxItems1(val)"/>

            <base-select-box
                :class="selectBoxClass3"
                :items="selectBoxItems2"
                @toParent:selectValue="val => changeSelectBoxItems2(val)"/>

            <base-select-box
                :class="selectBoxClass2"
                :items="cardBankCorpList"
                v-if="scrapGroup == 'CARD' || scrapGroup == 'BANK'"
                @toParent:selectValue="val => scrapSiteKey = val"/>

            <button type="button" class="btn btn-darkgrey ml15 mr20 w120" @click="getSchdManualList()">조회</button>
            <button type="button" class="btn btnwrap-right btn-default bold ml15 mr20 w280" @click="openGuide">수동실행 (등록 후 1회 수동실행) 안내</button>
          </div>
        </div>
        <!-- //검색영역  -->

        <!-- 수동실행 상세팝업 조회기준 세팅용 input hidden -->
        <input type="hidden" v-model="hiddenYear"/>
        <input type="hidden" v-model="hiddenYearQuarter"/>
        <input type="hidden" v-model="hiddenFromDate"/>
        <input type="hidden" v-model="hiddenToDate"/>
        <input type="hidden" v-model="hiddenVatQuarter"/>

        <!-- 조회 기준 : 일자 기준 & 기타 필요 정보 -->
        <div class="tx_rt pb10" v-if="scrapPeriodStandard == 'hometax_year_quarter'">
          <base-select-box
              :class="selectBoxClass2"
              :items="yearItems"
              @toParent:selectValue="val => changeSelectedOpt(val, yearItems)"/>
          <base-select-box
              :class="selectBoxClass2"
              :items="quarterItems"
              @toParent:selectValue="val => changeSelectedOpt(val, quarterItems)"/>
          <button class="btn btn-default ml10 mr5 mb5 mt5 w80"
                  @click.prevent="runManualYearQuarter(yearStandard, yearQuarterStandard)">수동실행
          </button>
        </div>
        <!-- 합계표 : 연도, 기수별 예정/확정/예정과확정 -->
        <div class="tx_rt pb10" v-if="scrapPeriodStandard == 'hometax_year_vat'">
          <base-select-box
              :class="selectBoxClass2"
              :items="yearItems"
              @toParent:selectValue="val => changeSelectedOpt(val, yearItems)"/>
          <base-select-box
              :class="selectBoxClass3"
              :items="vatItems"
              @toParent:selectValue="val => changeSelectedOpt(val, vatItems)"/>
          <button class="btn btn-default ml10 mr5 mb5 mt5 w80"
                  @click.prevent="runManualYearGisu(yearStandard, vatQuarterStandard)">수동실행
          </button>
        </div>
        <div class="tx_rt pb10"
             v-else-if="(scrapPeriodStandard == 'hometax_year' || scrapPeriodStandard == 'medi_year')">
          <base-select-box
              :class="selectBoxClass2"
              :items="yearItems"
              @toParent:selectValue="val => changeSelectedOpt(val, yearItems)"/>
          <button class="btn btn-default ml10 mr5 mb5 mt5 w80" @click.prevent="runManualYear(yearStandard)">수동실행</button>
        </div>
        <div class="tx_rt pb10" v-else-if="scrapPeriodStandard == 'hometax_unnecessary'">
          <button class="btn btn-default ml10 mr5 mb5 mt5 w80" @click.prevent="runManualUnNecessary()">수동실행</button>
        </div>
        <div class="tx_rt pb10"
             v-else-if="(scrapPeriodStandard == 'hometax_from_to_ymd' || scrapPeriodStandard == 'medi_from_to_ymd' || scrapPeriodStandard == 'default_from_to_ymd') && scrapGroup != ''">
          <base-calendar-in-search-row
              :isDateGubun="isDateGubun"
              @toParent:selectDateGubun="val => dateGubun = val"
              @toParent:setFromDate="val => fromDate = val"
              @toParent:setToDate="val => toDate = val">

            <!-- 세금신고결과조회 : 세목 selectBox -->
            <span slot="slot_selectBox_taxItem" v-if="isNeedTaxItem">
              <label class="tx_rt ml20 mr10 bold"><i class="fas fa-caret-right fs14"></i>세목</label>
              <base-select-box
                  :class="selectBoxClass1"
                  :items="taxItemList"
                  @toParent:selectValue="val => taxItem = val"/>
            </span>
            <button class="btn btn-default ml10 mr5 mb5 mt5 w80" slot="slot_searchBtn"
                    @click.prevent="runManualFromTo(fromDate, toDate)">수동실행
            </button>
          </base-calendar-in-search-row>
        </div>
        <div class="tx_rt pb10"
             v-else-if="(scrapPeriodStandard == 'nhis_from_to_ym' || scrapPeriodStandard == 'nps_from_to_ym' || scrapPeriodStandard == 'credit_from_to_ym' || scrapPeriodStandard == 'card_terminal_from_to_ym')">
          <base-calendar-search-row-for-year-month
              :isDateGubun="isDateGubun"
              @toParent:setFromDate="val => fromDate = val"
              @toParent:setToDate="val => toDate = val">

            <button class="btn btn-default ml10 mr5 mb5 mt5 w80" slot="slot_searchBtn"
                    @click.prevent="runManualFromTo(fromDate, toDate)">수동실행
            </button>
          </base-calendar-search-row-for-year-month>
        </div>
        <!-- //조회 기준 -->

        <!-- 수동실행 테이블: scrapGroup 선택했을 경우 -->
        <table class="table01" v-if="scrapGroup != ''">
          <caption>수동실행 가능내역 표</caption>
          <colgroup v-if="scrapGroup == 'HOMETAX' && isCompanyOrAgentType == 'AGENT'">
            <col style="width:60px;" v-if="isScrapGroupNeedCheckBoxYn()">
            <col v-for="(item, index) in tableItems.agent_heads" :key="index" :style="item.style">
          </colgroup>
          <colgroup v-if="scrapGroup == 'HOMETAX' && isCompanyOrAgentType == 'COMPANY'">
            <col style="width:60px;" v-if="isScrapGroupNeedCheckBoxYn()">
            <col v-for="(item, index) in tableItems.user_heads" :key="index" :style="item.style">
          </colgroup>
          <colgroup v-if="scrapGroup != 'HOMETAX'">
            <col style="width:60px;" v-if="isScrapGroupNeedCheckBoxYn()">
            <col v-for="(item, index) in tableItems.heads" :key="index" :style="item.style">
          </colgroup>
          <thead>
          <tr v-if="scrapGroup == 'HOMETAX' && isCompanyOrAgentType == 'AGENT'">
            <th class="tx_cnt" v-if="isScrapGroupNeedCheckBoxYn()">선택</th>
            <th v-for="(item, index) in tableItems.agent_heads" :key="index" class="tx_cnt" v-html="item.col">
              {{ item.col }}
            </th>
          </tr>
          <tr v-if="scrapGroup == 'HOMETAX' && isCompanyOrAgentType == 'COMPANY'">
            <th class="tx_cnt" v-if="isScrapGroupNeedCheckBoxYn()">선택</th>
            <th v-for="(item, index) in tableItems.user_heads" :key="index" class="tx_cnt" v-html="item.col">
              {{ item.col }}
            </th>
          </tr>
          <tr v-if="scrapGroup != 'HOMETAX'">
            <th class="tx_cnt" style="width:80px;" v-if="isScrapGroupNeedCheckBoxYn()">
              <label>
                <input class="mr5"
                            type="checkbox"
                            v-model="allSelected"/>
              </label>선택
            </th>
            <th v-for="(item, index) in tableItems.heads" :key="index" class="tx_cnt" v-html="item.col">
              {{ item.col }}
            </th>
          </tr>
          </thead>

          <!-- 수동실행 테이블-tbody 홈택스 > 일반사용자 -->
          <tbody v-if="scrapGroup == 'HOMETAX' && isCompanyOrAgentType == 'COMPANY' && tableItems.items.length != 0">
          <tr v-for="(item, index) in tableItems.items" :key="index">
            <td class="tx_cnt">{{ item.scrapName }}</td>
            <!-- 컬럼: 조회기준-->
            <td class="tx_cnt" v-if="scrapPeriodStandard == 'hometax_year_quarter'">
              {{ item.year }}년도&nbsp;&nbsp;{{ item.quarter }}분기
            </td>
            <td class="tx_cnt" v-else-if="scrapPeriodStandard == 'hometax_year'">{{ item.year }}년도</td>
            <td class="tx_cnt" v-else-if="scrapPeriodStandard == 'hometax_unnecessary'">&nbsp;-&nbsp;</td>
            <td class="tx_cnt"
                v-else-if="(scrapPeriodStandard == 'hometax_from_to_ymd' || scrapPeriodStandard == 'default_from_to_ymd') && scrapGroup != ''">
              {{ item.fromDate }} ~ {{ item.toDate }}
            </td>
            <!-- //컬럼: 조회기준 -->
            <td class="tx_cnt">{{ item.scrapCnt }}</td>
            <td class="tx_cnt txt-primary" v-if="item.result == null">{{ item.result }}</td>
            <td class="tx_cnt" v-if="item.result != null && item.result == '정상'">
              <div class="tooltip-succ">
                <span class="tooltip-succ-cursor txt-primary">{{ item.result }}</span>
                <span class="tooltiptext form-group has-success tx_cnt">{{ item.resultMessage }}</span>
              </div>
            </td>
            <!-- 실패 -->
            <td class="tx_cnt" v-if="item.result != null && item.result == '실패'">
              <div class="tooltip">
                <span class="tooltip-cursor txt-danger">{{ item.result }}</span>
                <span class="tooltiptext form-group has-error tx_cnt">{{ item.errorMsg }}</span>
              </div>
            </td>
          </tr>
          </tbody>
          <!-- 수동실행 테이블-tbody: 홈택스 > 세무대리인 -->
          <tbody v-if="scrapGroup == 'HOMETAX' && isCompanyOrAgentType == 'AGENT' && tableItems.items.length != 0">
          <tr v-for="(item, index) in tableItems.items" :key="index">
            <td class="tx_cnt">{{ item.scrapName }}</td>
            <!-- 컬럼: 조회기준-->
            <td class="tx_cnt" v-if="scrapPeriodStandard == 'hometax_year_quarter'">
              {{ item.year }}년도&nbsp;&nbsp;{{ item.quarter }}
            </td>
            <td class="tx_cnt" v-else-if="scrapPeriodStandard == 'hometax_year_vat'">
              {{ item.year }}년도&nbsp;&nbsp;{{item.vatQuarter }}
            </td>
            <td class="tx_cnt" v-else-if="scrapPeriodStandard == 'hometax_year'">{{ item.year }}년도</td>
            <td class="tx_cnt" v-else-if="scrapPeriodStandard == 'hometax_unnecessary'">&nbsp;-&nbsp;</td>
            <td class="tx_cnt"
                v-else-if="(scrapPeriodStandard == 'hometax_from_to_ymd' || scrapPeriodStandard == 'default_from_to_ymd') && scrapGroup != ''">
              {{ item.fromDate }} ~ {{ item.toDate }}
            </td>
            <!-- //컬럼: 조회기준 -->
            <td class="tx_cnt primary">
              <a style="text-decoration:underline;"
                 @click="openDetailsPopup()">{{ item.historyGroupDate }}</a>
            </td>
          </tr>
          </tbody>

          <!-- 수동실행 테이블-tbody: 여신금융/국민건강보험공단/국민연금관리공단/요양기관업무포털/요양기관정보마당 -->
          <tbody
              v-if="scrapGroup == 'CREDIT' || scrapGroup == 'NHIS' || scrapGroup == 'NPS' || scrapGroup == 'HIRA' || scrapGroup == 'MEDI' && tableItems.items.length != 0">
          <tr v-for="(item, index) in tableItems.items" :key="index">
            <td class="tx_cnt" v-if="isScrapGroupNeedCheckBoxYn()">
              <label>
                <input type="checkbox"
                       v-model="checkedScrapList"
                       :value="index">
              </label>
            </td>
            <td class="tx_cnt">{{ item.scrapName }}</td>
            <td class="tx_cnt" v-if="scrapPeriodStandard == 'medi_year'">{{ item.year }}년도</td>
            <td class="tx_cnt" v-else>{{ item.fromDate }} ~ {{ item.toDate }}</td>
            <td class="tx_cnt">{{ item.scrapCnt }}</td>
            <td class="tx_cnt txt-primary" v-if="item.result == null">{{ item.result }}</td>
            <td class="tx_cnt" v-if="item.result != null && item.result == '정상'">
              <div class="tooltip-succ">
                <span class="tooltip-succ-cursor txt-primary">{{ item.result }}</span>
                <span class="tooltiptext form-group has-success tx_cnt">{{ item.resultMessage }}</span>
              </div>
            </td>
            <!-- 실패 -->
            <td class="tx_cnt" v-if="item.result != null && item.result == '실패'">
              <div class="tooltip">
                <span class="tooltip-cursor txt-danger">{{ item.result }}</span>
                <span class="tooltiptext form-group has-error tx_cnt">{{ item.errorMsg }}</span>
              </div>
            </td>
          </tr>
          </tbody>

          <!-- 수동실행 테이블-tbody: 카드단말기 -->
          <tbody v-if="scrapGroup == 'CARD_TERMINAL' && tableItems.items.length != 0">
          <tr v-for="(item, index) in tableItems.items" :key="index">
            <td class="tx_cnt" v-if="isScrapGroupNeedCheckBoxYn()"><label><input type="checkbox"
                                                                                 v-model="checkedScrapList"
                                                                                 :value="index"></label></td>
            <td class="tx_cnt">{{ item.authId }}</td>
            <td class="tx_cnt">{{ item.scrapName }}</td>
            <td class="tx_cnt">{{ item.fromDate }} ~ {{ item.toDate }}</td>
            <td class="tx_cnt">{{ item.scrapCnt }}</td>
            <td class="tx_cnt txt-primary" v-if="item.result == null">{{ item.result }}</td>
            <td class="tx_cnt" v-if="item.result != null && item.result == '정상'">
              <div class="tooltip-succ">
                <span class="tooltip-succ-cursor txt-primary">{{ item.result }}</span>
                <span class="tooltiptext form-group has-success tx_cnt">{{ item.resultMessage }}</span>
              </div>
            </td>
            <!-- 실패 -->
            <td class="tx_cnt" v-if="item.result != null && item.result == '실패'">
              <div class="tooltip">
                <span class="tooltip-cursor txt-danger">{{ item.result }}</span>
                <span class="tooltiptext form-group has-error tx_cnt">{{ item.errorMsg }}</span>
              </div>
            </td>
          </tr>
          </tbody>

          <!-- 수동실행 테이블-tbody: 일반사용자 > 카드/은행 -->
          <tbody v-else-if="scrapGroup == 'CARD' || scrapGroup == 'BANK' && tableItems.items.length != 0">
          <tr v-for="(item, index) in tableItems.items" :key="index">
            <td class="tx_cnt" v-if="isScrapGroupNeedCheckBoxYn()"><label><input type="checkbox"
                                                                                 v-model="checkedScrapList"
                                                                                 :value="index"></label></td>
            <td class="tx_cnt">{{ item.bizKind.displayName }}</td>
            <td class="tx_cnt">
              <span class="displayblock pt5 lh20">{{ item.siteName }}</span>
              <span class="displayblock pb5">{{ item.financeNo }}</span>
            </td>
            <td class="tx_cnt">{{ item.scrapName }}</td>
            <td class="tx_cnt">{{ item.fromDate }} ~ {{ item.toDate }}</td>
            <td class="tx_cnt">{{ item.scrapCnt }}</td>
            <td class="tx_cnt txt-primary" v-if="item.result == null">{{ item.result }}</td>
            <td class="tx_cnt" v-if="item.result != null && item.result == '정상'">
              <div class="tooltip-succ">
                <span class="tooltip-succ-cursor txt-primary">{{ item.result }}</span>
                <span class="tooltiptext form-group has-success tx_cnt">{{ item.resultMessage }}</span>
              </div>
            </td>
            <!-- 실패 -->
            <td class="tx_cnt" v-if="item.result != null && item.result == '실패'">
              <div class="tooltip">
                <span class="tooltip-cursor txt-danger">{{ item.result }}</span>
                <span class="tooltiptext form-group has-error tx_cnt">{{ item.errorMsg }}</span>
              </div>
            </td>
            <td class="tx_cnt" v-if="item.result != null && item.result == '정상'">{{ item.resultMessage }}</td>
            <td class="tx_cnt" v-if="item.result != null && item.result == '실패'">{{ item.errorMsg }}</td>
          </tr>
          </tbody>

          <!-- 수동실행 테이블-tbody: 조회 데이터 없을 경우 -->
          <tbody v-if="scrapGroup == 'HOMETAX' && isCompanyOrAgentType == 'COMPANY' && tableItems.items.length == 0">
          <tr>
            <td class="tx_cnt" colspan="4">조회된 데이터가 없습니다.</td>
          </tr>
          </tbody>
          <tbody v-if="scrapGroup == 'HOMETAX' && isCompanyOrAgentType == 'AGENT' && tableItems.items.length == 0">
          <tr>
            <td class="tx_cnt" colspan="3">조회된 데이터가 없습니다.</td>
          </tr>
          </tbody>

          <tbody
              v-if="(scrapGroup == 'CREDIT' || scrapGroup == 'NHIS' || scrapGroup == 'NPS' || scrapGroup == 'HIRA' || scrapGroup == 'MEDI') && tableItems.items.length == 0">
          <tr>
            <td class="tx_cnt" colspan="5">조회된 데이터가 없습니다.</td>
          </tr>
          </tbody>

          <tbody v-if="(scrapGroup == 'CARD' || scrapGroup == 'BANK') && tableItems.items.length == 0">
          <tr>
            <td class="tx_cnt" colspan="9">조회된 데이터가 없습니다.</td>
          </tr>
          </tbody>

          <tbody v-if="(scrapGroup == 'CARD_TERMINAL') && tableItems.items.length == 0">
          <tr>
            <td class="tx_cnt" colspan="6">조회된 데이터가 없습니다.</td>
          </tr>
          </tbody>
          <!-- //수동실행 테이블-tbody: 조회 데이터 없을 경우 -->

        </table>

        <!-- 수동실행 테이블: scrapGroup 선택 안했을 경우 -->
        <table v-else-if="scrapGroup == ''" style="height:250px">
          <tbody>
          <tr>
            <td class="tx_cnt"><span>조회할 자료를 선택해주세요.</span></td>
          </tr>
          </tbody>
        </table>
        <!-- //수동실행 테이블 -->

        <!-- Paging -->
        <base-pagination
            v-if="isScrapGroupNeedCheckBoxYn()"
            :prefix="'/scrap/manual'"

            :pTotalListItemCount="totalListCnt"
            :pageInit="pageInit"
            @toParent:changePage="(val1, val2) => currPage = val2"/>

        <div v-if="scrapGroup == 'NPS' && tableItems.items.length != 0" class="warn-box">
          <div class="warn-middle-box">
            <span class="warn-text">부가세 신고 기간 연금보험료결정내역 스크랩핑 수집이 오래 걸리고 있습니다.</span><br/>
            <span class="warn-text">실패 응답시 잠시후 스크랩핑 결과조회에서 결과를 확인 바랍니다</span>
          </div>
        </div>

        <!-- 세무대리인 수임사 선택 영역 -->
        <button @click="showCorpList(scrapId)" class="btn btn-accd w100p mt30"
                v-if="scrapGroup == 'HOMETAX' && isCompanyOrAgentType == 'AGENT'">수임사 선택 <i
            class="fas fa-arrow-circle-right pl5 text-gray5"></i></button>
        <base-agent-corp-choose-table
            :agentVisible="agentVisible"
            :parentCorpList="agentCorpTableItems"
            :scrapId="scrapId"
            @toParent:companyIdxList="val => agentChooseCorpIdxList = val"/>
        <!-- //세무대리인 수임사 선택 영역 -->
      </div>
    </div>
    <!-- //스크랩핑 수동실행 -->
    <base-scrap-manual-details-popup
        :popupTitle="agentDetailsPopupTitle"
        :scrapId="scrapId"
        :visible="popupVisible"
        :groupKey="agentManualGroupKey"
        :fromDate="hiddenFromDate"
        :toDate="hiddenToDate"
        :year="hiddenYear"
        :yearQuarter="hiddenYearQuarter"
        :vatQuarter="hiddenVatQuarter"
        :scrapPeriodStandard="scrapPeriodStandard"
        :needSearchAndPaging="needSearchAndPaging"
        @toParent:visible="val => popupVisible = val"/>
  </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex';

export default {
  name: 'PageScrapingManual',

  data: () => ({
    // Variables
    jsonHeader: {
      headers: {
        'Content-Type': 'application/json;'
      }
    },
    // 검색영역 관련
    scrapGroup: '',
    scrapId: '',
    scrapName: '',
    scrapSiteKey: '',
    isDateGubun: false,
    fromDate: '',
    toDate: '',
    checkedScrapList: [],
    //수동실행 그리드 데이터 조회유무 체크용
    isReadyForScrapManual: false,

    // 스크랩핑 date 기준 관련
    yearStandard: '',
    yearQuarterStandard: '',
    vatQuarterStandard: '',
    //  세목 taxItem 선택값
    taxItem: '',
    // // 세목 selectBox visible용
    isNeedTaxItem: false,

    // 세무대리인용 상세팝업 조회기준 표시용 hidden값
    hiddenYear: '',
    hiddenYearQuarter: '',
    hiddenFromDate: '',
    hiddenToDate: '',
    hiddenVatQuarter: '',
    needSearchAndPaging: true,

    // 페이지 관련
    currPage: 1,
    pageLimit: 50,
    pageInit: false, //현재 페이지 초기화 여부 체크용
    totalListCnt: 0, //조회내역 총 갯수. 페이지수 계산용으로 필요

    //팝업 관련
    agentDetailsPopupTitle: '세무대리인 수동실행 사업장별 상세내역',
    popupVisible: false,
    agentManualGroupKey: '',

    //하단 세무대리인용 특정 수임사 선택 영역
    agentVisible: false,
    agentCorpTableItems: [],
    agentChooseTableItems: [],
    agentChooseCorpIdxList: [],	//최종 수임사 idx 배열

    // 첫번째 그리드 관련     {col: '선택', style: 'width:40px'},
    tableItems: '',
    hometaxTableItems: {
      user_heads: [
        {col: '자동수집 종류', style: 'width:300px'},
        {col: '조회기준', style: ''}, {col: '조회건수', style: 'width:150px'}, {col: '결과', style: 'width:150px'}
      ],
      agent_heads: [
        {col: '자동수집 종류', style: 'width:300px'},
        {col: '조회기준', style: ''}, {col: '최근 실행 시간', style: 'width:500px'}
      ],
      items: [],
    },
    cardTableItems: {
      heads: [
        {col: '대상', style: 'width:80px'}, {col: '금융기관<br/>카드번호', style: 'width:300px'},
        {col: '자동수집 종류', style: 'width:180px'}, {col: '조회기준', style: 'width:300px'},
        {col: '조회건수', style: 'width:80px'}, {col: '결과', style: 'width:80px'},
        {col: '결과메세지', style: 'width:200px'},
      ],
      items: [],
    },
    bankTableItems: {
      heads: [
        {col: '대상', style: 'width:80px'}, {col: '금융기관<br/>계좌번호', style: ''},
        {col: '자동수집 종류', style: 'width:180px'}, {col: '조회기준', style: 'width:300px'},
        {col: '조회건수', style: 'width:80px'}, {col: '결과', style: 'width:80px'},
        {col: '결과메세지', style: 'width:200px'},
      ],
      items: [],
    },
    creditTableItems: {
      heads: [
        {col: '자동수집 종류', style: 'width:300px'}, {col: '조회기준', style: 'width:300px'},
        {col: '조회건수', style: 'width:150px'}, {col: '결과', style: 'width:150px'},
      ],
      items: [],
    },
    nhisTableItems: {
      heads: [
        {col: '자동수집 종류', style: 'width:300px'}, {col: '조회기준', style: ''},
        {col: '조회건수', style: 'width:150px'}, {col: '결과', style: 'width:150px'},
      ],
      items: [],
    },
    npsTableItems: {
      heads: [
        {col: '자동수집 종류', style: 'width:300px'}, {col: '조회기준', style: ''},
        {col: '조회건수', style: 'width:150px'}, {col: '결과', style: 'width:150px'},
      ],
      items: [],
    },
    hiraTableItems: {
      heads: [
        {col: '자동수집 종류', style: 'width:300px'}, {col: '조회기준', style: ''},
        {col: '조회건수', style: 'width:150px'}, {col: '결과', style: 'width:150px'},
      ],
      items: [],
    },
    mediTableItems: {
      heads: [
        {col: '자동수집 종류', style: 'width:300px'}, {col: '조회기준', style: ''},
        {col: '조회건수', style: 'width:150px'}, {col: '결과', style: 'width:150px'},
      ],
      items: [],
    },
    cardTerminalTableItems: {
      heads: [
        {col: 'id', style: 'width:300px'}, {col: '자동수집 종류', style: 'width:300px'},
        {col: '조회기준', style: ''}, {col: '조회건수', style: 'width:150px'},
        {col: '결과', style: 'width:150px'},
      ],
      items: [],
    },

    // Components attrs
    selectBoxClass1: 'form-control pl5 form-control-inline w150 mr5',
    selectBoxClass2: 'form-control form-control-inline w90 mr5',
    selectBoxClass3: 'form-control pl5 form-control-inline w200 mr5',
    selectBoxItems1: [
      {key: '', value: '선택',},
    ],
    selectBoxItems2: [
      // {key: '', value: '전체'},
      {key: '', value: '자동수집 내역 선택', selected: true},
    ],
    // selectBoxItems_taxItem: [], //  세목 selectBox용
    cardBankCorpList: [
      {key: '', value: '전체'},
    ],
    yearItems: [
      {key: '', value: '연도 선택', selected: true}
    ],
    quarterItems: [
      {key: '', value: '분기 선택', selected: true},
      {key: 'Q1', value: '1분기', selected: false},
      {key: 'Q2', value: '2분기', selected: false},
      {key: 'Q3', value: '3분기', selected: false},
      {key: 'Q4', value: '4분기', selected: false}
    ],
    vatItems: [
      {key: '', value: '신고구분', selected: true},
      {key: 'VAT_1', value: '1기 예정', selected: false},
      {key: 'VAT_2', value: '1기 확정', selected: false},
      {key: 'VAT_3', value: '1기 예정+확정', selected: false},
      {key: 'VAT_4', value: '2기 예정', selected: false},
      {key: 'VAT_5', value: '2기 확정', selected: false},
      {key: 'VAT_6', value: '2기 예정+확정', selected: false},
    ]
  }),
  methods: {
    //========== 검색영역 메서드 1. selectBox 초기화 및 검색영역 param 세팅 ==========
    changeSelectBoxItems1(val) {
      this.scrapGroup = val;
      this.scrapId = '';
      this.scrapName = '';
      this.yearStandard = '';
      this.yearQuarterStandard = '';
      this.vatQuarterStandard = '';
      this.isReadyForScrapManual = false;

      // selectBox2 초기화
      this.selectBoxItems2 = [
        // {key: '', value: '전체'}
        {key: '', value: '자동수집 내역 선택', selected: true}
      ];
      // selectBox3 cardBankCorpList 초기화
      this.cardBankCorpList = [
        {key: '', value: '전체'},
      ];
      // 그리드 초기화 (안하면, 이전에 조회한거 그대로 남아있음)
      if (this.tableItems.items != null) {
        this.tableItems.items = [];
        this.pageInit = true;	//현재 페이지 초기화
        this.totalListCnt = 0;	//페이지수 초기화
      }
      this.hiddenYear = '';
      this.hiddenYearQuarter = '';
      this.hiddenFromDate = '';
      this.hiddenToDate = '';
      this.hiddenVatQuarter = '';
      //수임사 선택 영억 초기화
      this.agentVisible = false;
      //  수임사 선택 영역 닫을 경우, 전체 수임사 수동실행하도록 비워주기
      this.agentChooseCorpIdxList = [];
    },
    changeSelectBoxItems2(val) {
      this.yearStandard = '';
      this.yearQuarterStandard = '';
      this.vatQuarterStandard = '';
      this.isReadyForScrapManual = false;

      //	selectBox selected 속성값 변경
      this.scrapId = val;
      for (var i = 0; i < this.selectBoxItems2.length; i++) {
        if (this.selectBoxItems2[i].key == val) {
          this.selectBoxItems2[i].selected = true;
        } else {
          this.selectBoxItems2[i].selected = false;
        }
      }

      // 그리드 초기화 (안하면, 이전에 조회한거 그대로 남아있음)
      if (this.tableItems.items != null) {
        this.tableItems.items = [];

        this.pageInit = true;	//현재 페이지 초기화
        this.totalListCnt = 0;	//페이지수 초기화
      }
    },
    //	연도 selected값 변경
    changeSelectedOpt(val, items) {

      if (items == this.yearItems) {
        this.yearStandard = val;
      } else if (items == this.quarterItems) {
        this.yearQuarterStandard = val;
      } else if (items == this.vatItems) {
        this.vatQuarterStandard = val;
      }

      for (var i = 0; i < items.length; i++) {
        if (items[i].key == val) {
          items[i].selected = true;
        } else {
          items[i].selected = false;
        }
      }
    },
    //	연도 selected값 변경
    changeSelectedOpt1(val) {

      this.yearStandard = val;

      for (var i = 0; i < this.yearItems.length; i++) {
        if (this.yearItems[i].key == val) {
          this.yearItems[i].selected = true;
        } else {
          this.yearItems[i].selected = false;
        }
      }
    },
    //	분기 selected값 변경
    changeSelectedOpt2(val) {

      this.yearQuarterStandard = val;

      for (var i = 0; i < this.quarterItems.length; i++) {
        if (this.quarterItems[i].key == val) {
          this.quarterItems[i].selected = true;
        } else {
          this.quarterItems[i].selected = false;
        }
      }
    },

    //========== 검색영역 메서드 2. 스크랩핑 그룹 목록  ==========
    getScrapGroup() {
      this.$cAxios.post("/api/scrap/manual/getScrapGroupList")
          .then((response) => {
            if (response.data.length > 0) {
              // selectBox1 세팅
              for (var i = 0; i < response.data.length; i++) {
                this.obj = {};
                this.obj.key = response.data[i].id;
                this.obj.value = response.data[i].name;
                this.selectBoxItems1.push(this.obj);
              }
            }
          }).catch((error) => {
            console.log(error.response.data.message);
          })
    },
    //========== 검색영역 메서드 3. 스크랩핑 내역 목록  ==========
    getScrap(scrapGroup) {
      this.$cAxios.post("/api/scrap/manual/getScrapList", "scrapGroup=" + scrapGroup)
          .then((response) => {

            if (response.data.length > 0) {
              // selectBox2 세팅
              this.selectBoxItems2 = [];

              if (this.isScrapGroupNeedCheckBoxYn()) {
                //체크박스 필요한 scrapGroup인 경우, selectbox에 '전체' 넣기
                this.selectBoxItems2.push({key: '', value: '전체', selected: false});
                for (var i = 0; i < response.data.length; i++) {
                  this.selectBoxItems2.push({key: response.data[i].id, value: response.data[i].name, selected: false});
                }
                this.changeSelectBoxItems2('');

              } else {
                //체크박스 필요없는 scrapGroup인 경우, scrapId 초기값 특정하기
                for (var j = 0; j < response.data.length; j++) {
                  this.selectBoxItems2.push({key: response.data[j].id, value: response.data[j].name, selected: false});
                }
                this.changeSelectBoxItems2(response.data[0].id);
              }

              // cardBankCorpList 세팅 메서드 호출 (신용카드 은행계좌)
              if (this.selectBoxItems2.length > 1 && (scrapGroup == 'CARD' || scrapGroup == 'BANK')) {
                this.getScrapSite(scrapGroup);
              }
            }
          }).catch((error) => {
            console.log(error.response.data.message);
          })
    },
    //========== 검색영역 메서드 4. 신용카드사/은행사 목록 ==========
    getScrapSite(scrapGroup) {
      // cardBankCorpList 세팅
      this.$cAxios.post("/api/scrap/manual/getScrapSiteList", "scrapGroup=" + scrapGroup)
          .then(response => {

            if (response.data.length > 0) {
              for (var i = 0; i < response.data.length; i++) {
                this.obj = {};
                this.obj.key = response.data[i].idx;
                this.obj.value = response.data[i].name;

                this.cardBankCorpList.push(this.obj);
              }
            }
          }).catch((error) => {
            console.log(error.response.data.message);
          })
    },
    //========== 그리드 조회 1. checkbox & pagination 필요 유무 분기 ==========
    getSchdManualList() {

      if (this.isScrapGroupNeedCheckBoxYn()) {

        this.getSchdManualListWithPagination(true);
      } else {

        this.getSchdManualListWithoutPagination();
      }
      //수동실행 그리드 데이터 조회유무 체크용
      this.isReadyForScrapManual = true;
    },
    //========== 그리드 조회 2. checkbox & pagination 필요한 경우 ==========
    getSchdManualListWithPagination(pageReloadYn) {
      // 검색 조건 재적용해서 새로 조회할 경우
      if (pageReloadYn) {
        this.pageInit = true;	//현재 페이지 초기화
        this.totalListCnt = 0;	//페이지수 초기화
        this.currPage = 1;    //이 스크립트용 현재페이지값 초기화 (서버 보내줘야함)
      }

      this.checkedScrapList = [];

      if (this.scrapGroup == '') {
        alert('조회할 자료를 선택하세요.');

        return false;

      } else {
        // params 세팅
        this.searchParams = {};
        this.searchParams.scrapGroupId = this.scrapGroup;
        // this.searchParams.scrapId = this.scrapName;
        this.searchParams.scrapId = this.scrapId;

        for (var i = 0; i < this.cardBankCorpList.length; i++) {
          if (this.cardBankCorpList[i].key == this.scrapSiteKey && this.cardBankCorpList[i].key != '') {
            this.searchParams.scrapSiteName = this.cardBankCorpList[i].value;
          }
        }

        this.searchParams.pageIndex = this.currPage - 1;
        this.searchParams.pageLimit = this.pageLimit;
      }

      this.$cAxios.post("/api/scrap/manual/getScheduleListWithPagination", JSON.stringify(this.searchParams), this.jsonHeader)
          .then(response => {

            // 테이블 초기화
            this.tableItems.items = [];
            if (response.data.content.length > 0) {
              this.totalListCnt = response.data.totalElements;

              for (var i = 0; i < response.data.content.length; i++) {
                // 그리드 item 배열에 추가
                this.tableItems.items.push(response.data.content[i]);
              }
            }
          }).catch(error => {
        console.log(error.response.data.message);

      }).finally(() => {
        this.pageInit = false;
      })
    },
    //========== 그리드 조회 3. checkbox & pagination 필요없는 경우 ===========
    getSchdManualListWithoutPagination() {
      // params 세팅
      this.searchParams = {};
      this.searchParams.scrapGroupId = this.scrapGroup;
      this.searchParams.scrapId = this.scrapId;

      this.$cAxios.post("/api/scrap/manual/getScheduleData", JSON.stringify(this.searchParams), this.jsonHeader)
          .then((response) => {

            // 테이블 초기화
            this.tableItems.items = [];
            if (Object.keys(response.data).length > 0) {
              this.tableItems.items.push(response.data);
              this.scrapName = response.data.scrapName;
            }

          }).catch(error => {
        console.log(error.response.data.message);
      })

    },

    //========== 수동실행 (시작일~종료일 기준) 호출 메서드 . ==========
    runManualFromTo(fromDate, toDate) {
      if (!this.isReadyForScrapManual) {
        return alert('상단의 조회버튼을 눌러 수동실행할 자료를 불러오세요.');
      }
      //조회기준 세팅
      if (this.setFromToDate(fromDate, toDate)) {

        this.paramList = [];
        //체크박스 있는 경우
        if (this.isScrapGroupNeedCheckBoxYn()) {
          // 선택한 스크랩핑이 있을경우에 수동실행
          if (this.checkedScrapList.length > 0) {
            //이전 수동실행 데이터 (조회일자, 조회건수, 결과) 초기화
            this.clearBeforeData();
            // 선택한 스크랩핑 정보 배열로 세팅
            for (var i = 0; i < this.checkedScrapList.length; i++) {
              // 선택한 스크랩핑 스케줄의 그리드 인덱스
              var index = this.checkedScrapList[i];

              var startDate1 = this.tableItems.items[index].fromDate;
              var endDate1 = this.tableItems.items[index].toDate
              if (startDate1 == null || endDate1 == null) {
                return alert('조회일자를 설정하세요.');
              } else {
                this.param = {};
                this.param.scrapGroupId = this.tableItems.items[index].scrapGroupId;
                this.param.scrapId = this.tableItems.items[index].scrapId;
                this.param.siteIdx = this.tableItems.items[index].siteIdx;

                //	2021-09-30 : 카드(checkbox) 다건 실행시, from to가 안넘어가는 경우 방지하기 위해 수정
                this.param.fromDate = this.getFormatDate(fromDate);
                this.param.toDate = this.getFormatDate(toDate);

                this.param.financeIdx = this.tableItems.items[index].financeIdx;

                this.paramList.push(this.param);
              }
            }
            // 재귀함수 호출
            this.callScrapManualWithCheckBox(this.paramList, 0);
          }
        } else {  //체크박스 없는 경우
          var startDate2 = this.tableItems.items[0].fromDate;
          var endDate2 = this.tableItems.items[0].toDate
          if (startDate2 == null || endDate2 == null) {
            return alert('조회일자를 설정하세요.');
          }
          this.param = {};

          this.param.scrapGroupId = this.tableItems.items[0].scrapGroupId;
          this.param.scrapId = this.tableItems.items[0].scrapId;
          this.param.siteIdx = this.tableItems.items[0].siteIdx;
          this.param.fromDate = this.tableItems.items[0].fromDate;
          this.param.toDate = this.tableItems.items[0].toDate;
          this.param.financeIdx = this.tableItems.items[0].financeIdx;

          //  hometax > agent > 세금신고결과조회 HOMETAX_DECLARE_RESULT : 세목 param 적용 필요  @@
          if (this.scrapId == 'HOMETAX_DECLARE_RESULT') {
            if (this.taxItem == '') {
              return alert('세목을 선택하세요.');
            } else {
              this.param.taxItem = this.taxItem;
            }
          }

          //상세팝업 조회기준 세팅
          this.hiddenFromDate = startDate2;
          this.hiddenToDate = endDate2;
          //스크랩핑 호출
          this.callScrapManualWithoutCheckBox(this.param);
        }
      }
    },
    //========== 수동실행: (연도 기준) 호출 메서드 . ==========
    runManualYear(year) {
      if (!this.isReadyForScrapManual) {
        return alert('상단의 조회버튼을 눌러 수동실행할 자료를 불러오세요.');
      }
      if (year == '') {
        return alert('연도를 선택해주세요.');
      }

      this.paramList = [];
      //체크박스 있는 경우
      if (this.isScrapGroupNeedCheckBoxYn()) {
        // 선택한 스크랩핑이 있을경우에 수동실행
        if (this.checkedScrapList.length > 0) {
          //이전 수동실행 데이터 (조회일자, 조회건수, 결과) 초기화
          this.clearBeforeData();

          // 선택한 스크랩핑 정보 배열로 세팅
          for (var i = 0; i < this.checkedScrapList.length; i++) {
            // 선택한 스크랩핑 스케줄의 그리드 인덱스
            var index = this.checkedScrapList[i];

            //  param 세팅
            this.param = {
              scrapGroupId: this.tableItems.items[index].scrapGroupId,
              scrapId: this.tableItems.items[index].scrapId,
              siteIdx: this.tableItems.items[index].siteIdx,
              year: year,
            };
            this.paramList.push(this.param);

          }
          //  재귀함수 호출
          this.callScrapManualWithCheckBox(this.paramList, 0);
        }
      } else {
        //  체크박스 없는 경우  ex) 홈택스

        //조회기준 세팅
        this.tableItems.items[0].year = year;

        this.param = {
          scrapGroupId: this.tableItems.items[0].scrapGroupId,
          scrapId: this.tableItems.items[0].scrapId,
          siteIdx: this.tableItems.items[0].siteIdx,
          year: year,
          // financeIdx: ,
        };
        //상세 팝업 조회기준 세팅
        this.hiddenYear = year;

        //스크랩핑 호출
        this.callScrapManualWithoutCheckBox(this.param);
      }
    },
    //========== 수동실행: 홈택스 중 (연도 & 분기 기준) 호출 메서드 . ==========
    runManualYearQuarter(year, quarter) {
      if (!this.isReadyForScrapManual) {
        return alert('상단의 조회버튼을 눌러 수동실행할 자료를 불러오세요.');
      }
      if (year == '' || quarter == '') {
        return alert('연도 및 분기를 선택해주세요.');
      }
      //상세 팝업 조회기준 세팅
      this.hiddenYear = year;
      this.hiddenYearQuarter = this.quarterItems.find(value => {
        if (value.key === quarter) return true
      }).value;

      //조회기준 세팅
      this.tableItems.items[0].year = year;
      this.tableItems.items[0].quarter = this.hiddenYearQuarter;

      this.param = {
        scrapGroupId: this.tableItems.items[0].scrapGroupId,
        scrapId: this.tableItems.items[0].scrapId,
        siteIdx: this.tableItems.items[0].siteIdx,
        year: year,
        type: 'yearQuarter',
        quarter: quarter,

        // financeIdx: ,
      };

      //스크랩핑 호출
      this.callScrapManualWithoutCheckBox(this.param);
    },
    //========== 수동실행: 홈택스 중 (연도 & 분기 기준) 호출 메서드 . ==========
    runManualYearGisu(year, vatQuarter) {
      if (!this.isReadyForScrapManual) {
        return alert('상단의 조회버튼을 눌러 수동실행할 자료를 불러오세요.');
      }
      if (year == '' || vatQuarter == '') {
        return alert('연도 및 기수 예정/확정을 선택해주세요.');
      }
      //상세 팝업 조회기준 세팅
      this.hiddenYear = year;
      this.hiddenVatQuarter = this.vatItems.find(value => {
        if (value.key === vatQuarter) return true
      }).value;

      //조회기준 세팅

      this.tableItems.items[0].year = year;
      this.tableItems.items[0].vatQuarter = this.hiddenVatQuarter;

      this.param = {
        scrapGroupId: this.tableItems.items[0].scrapGroupId,
        scrapId: this.tableItems.items[0].scrapId,
        siteIdx: this.tableItems.items[0].siteIdx,
        year: year,
        type: 'vatQuarter',
        quarter: vatQuarter,
        // financeIdx: ,
      };

      //스크랩핑 호출
      this.callScrapManualWithoutCheckBox(this.param);
    },
    //========== 수동실행: 홈택스 중 (날짜 관련 기준 필요X) 호출 메서드 . ==========
    runManualUnNecessary() {
      if (!this.isReadyForScrapManual) {
        return alert('상단의 조회버튼을 눌러 수동실행할 자료를 불러오세요.');
      }

      this.param = {
        scrapGroupId: this.tableItems.items[0].scrapGroupId,
        scrapId: this.tableItems.items[0].scrapId,
        siteIdx: this.tableItems.items[0].siteIdx,
        // financeIdx: ,
      };

      this.callScrapManualWithoutCheckBox(this.param);
    },
    //========== 수동실행 메서드 1. 재귀 함수. ==========
    callScrapManualWithCheckBox(paramList, index) {
      // checkedIdx : 선택한 스크랩핑 스케줄을 찾기위한 그리드의 인덱스값
      var checkedIdx = this.checkedScrapList[index];

      // index : loop 위한 인덱스값
      if (index >= this.checkedScrapList.length) {
        //  2021-02-17 : 완료 alert 최종 1번 나오도록 수정
        alert('자동수집이 완료되었습니다.');
        return false;
      }

      if (index < this.checkedScrapList.length)
        return this.$cAxios.post('/api/scrap/manual/scrapManual', JSON.stringify(paramList[index]), this.jsonHeader)
            .then(response => {

              this.tableItems.items[checkedIdx].result = '정상';
              this.tableItems.items[checkedIdx].scrapCnt = response.data.count;
              this.tableItems.items[checkedIdx].resultMessage = response.data.resultMessage;
            })
            .catch(error => {

              this.tableItems.items[checkedIdx].result = '실패';
              this.tableItems.items[checkedIdx].errorMsg = error.response.data.message;
            })
            .finally(() => {
              index++;
              this.callScrapManualWithCheckBox(paramList, index);
            })
    },
    //========== 수동실행 메서드 2. 스크랩핑 내역 단건.(수임사 전체 or 특정 여기서 param세팅) ==========
    callScrapManualWithoutCheckBox(param) {

      // 세무대리인일 경우
      if (this.isCompanyOrAgentType == 'AGENT') {
        // 특정 수임사 배열있을 경우, param 세팅
        if (this.agentChooseCorpIdxList.length > 0) {
          param.companies = this.agentChooseCorpIdxList;
        }
        this.$cAxios.post('/api/scrap/manual/scrapManualForAgent', JSON.stringify(param), this.jsonHeader)
            .then(response => {

              if (response.data != null) {
                alert('자동수집이 완료되었습니다.');

                this.tableItems.items[0].historyGroupDate = response.data.historyGroupDate;
                this.agentManualGroupKey = response.data.historyGroupKey;
                this.agentDetailsPopupTitle = this.scrapName;
                this.popupVisible = true;
              }
            })
            .catch(error => {
              console.log(error.response.status);
              // 세무대리인 자동수집 중 history 생성안됐을 경우
              if (error.response.status == 500) {
                alert(error.response.data.message);
              } else {
                alert('오류가 발생했습니다. (' + error.response.status + ')');
              }
            })

      } else {
        // 일반사용자일 경우
        this.$cAxios.post('/api/scrap/manual/scrapManual', JSON.stringify(param), this.jsonHeader)
            .then(response => {
              alert('자동수집이 완료되었습니다.');
              // history 데이터 받는 걸로 변경필요

              //이전 수동실행 데이터 (조회일자, 조회건수, 결과) 초기화
              this.tableItems.items[0].scrapCnt = '';
              this.tableItems.items[0].result = null;

              this.tableItems.items[0].result = '정상';
              this.tableItems.items[0].scrapCnt = response.data.count;
              this.tableItems.items[0].resultMessage = response.data.resultMessage;
            })
            .catch(error => {
              this.tableItems.items[0].result = '실패';
              this.tableItems.items[0].errorMsg = error.response.data.message;
            });
      }

    },
    //========== 수동실행 상세 팝업 오픈 . ==========
    openDetailsPopup() {
      this.popupVisible = true;
    },
    //========== 세무대리인용 하단 수임사 영역 visible 메서드 . ==========
    showCorpList(scrapId) {
      if (this.agentVisible == true) {
        this.agentVisible = false;

        //  수임사 선택 영역 닫을 경우, 전체 수임사 수동실행하도록 비워주기
        this.agentChooseCorpIdxList = [];
      } else {
        this.agentVisible = true;

        // 세무대리인 수임사 목록 조회
        if (this.isCompanyOrAgentType == 'AGENT') {
          this.getAgentMngCorpList(scrapId);
        }
      }
    },
    //========== 세무대리인 수임사 목록 조회 . ==========
    getAgentMngCorpList(scrapId) {
      this.agentCorpTableItems = [];

      // 개인/법인 사업자용 스크랩핑 내역인지 구분
      // let bizKind = '';
      // if (this.isNeedResNoScrapYn(scrapId)) {
      //   bizKind = 'PERSON';
      // } else {
      //   bizKind = 'CORP';
      // }
      // this.searchParams = {
      //   bizKind: bizKind
      // };
      // this.$cAxios.post('/api/scrap/manual/getAgentMngCorpList', JSON.stringify(this.searchParams), this.jsonHeader)
      this.$cAxios.post('/api/scrap/manual/getAgentMngCorpList', "scrapId=" + this.scrapId)
          .then(response => {
            for (var i = 0; i < response.data.length; i++) {
              this.agentCorpTableItems.push(response.data[i]);
            }
          })
          .catch(error => {
            console.log(error.response.data.message);
          })
    },
    //========== 기타 메서드 . ==========
    // 연도 계산
    calYearList() {
      var currentYear = new Date().getFullYear();

      // this.yearItems = [];
      for (var i = 0; i <= 2; i++) {
        this.yearItems.push({key: String(currentYear - i), value: String(currentYear - i), selected: false});
      }
    },
    // 조회 기간 적용
    setFromToDate(fromDate, toDate) {

      if (this.checkedScrapList.length <= 0) {
        if (this.isScrapGroupNeedCheckBoxYn()) {

          return alert('조회기간을 적용할 자동수집 내역을 선택하세요.');
        } else {
          // scrapGroup이 checkbox & pagination 필요없는 경우 해당 스크랩핑 내역에 일자 세팅
          this.tableItems.items[0].fromDate = this.getFormatDate(fromDate);
          this.tableItems.items[0].toDate = this.getFormatDate(toDate);
        }
      } else {

        if (this.scrapGroup == 'CARD' || this.scrapGroup == 'BANK') {
          const endDate = new Date(toDate);
          const startDate = new Date(fromDate);

          const beforeOneMonthDate = new Date();
          beforeOneMonthDate.setTime(endDate.getTime() - (30 * 24 * 3600 * 1000));

          if (startDate < beforeOneMonthDate) {
            alert('조회 시작일과 마감일은 최대 30일 간격으로 설정하세요.');
            return false;

          } else {
            for (var i = 0; i < this.checkedScrapList.length; i++) {
              const idxOne = this.checkedScrapList[i];

              this.tableItems.items[idxOne].fromDate = this.getFormatDate(fromDate);
              this.tableItems.items[idxOne].toDate = this.getFormatDate(toDate);
            }
          }
        } else {
          //추후 isScrapGroupNeedCheckBox에 추가 시킬 경우 else { } 필요X
          for (var j = 0; j < this.checkedScrapList.length; j++) {
            const idxTwo = this.checkedScrapList[j];

            this.tableItems.items[idxTwo].fromDate = this.getFormatDate(fromDate);
            this.tableItems.items[idxTwo].toDate = this.getFormatDate(toDate);
          }
        }
      }
      return true;
    },
    // 일자  - 제거
    getFormatDate(date) {
      var arr = date.split('-');
      var formatDate = '';
      for (var i = 0; i < arr.length; i++) {
        formatDate += arr[i];
      }
      return formatDate;
    },
    // 조회 시작일 마감일 간격 체크
    checkIntervalFromToDate(fromDate, toDate) {

      const beforeOneMonthDate = new Date();
      beforeOneMonthDate.setTime(toDate.getTime() - (30 * 24 * 3600 * 1000));

      return fromDate >= beforeOneMonthDate;
    },
    // 체크하지 않은 수동실행 데이터(조회일자, 조회건수, 결과) 초기화
    clearBeforeData() {
      this.totalItemList = [];
      for (var j = 0; j < this.tableItems.items.length; j++) {
        this.totalItemList.push(j);
      }
      for (var k = 0; k < this.totalItemList.length; k++) {
        for (var l = 0; l < this.checkedScrapList.length; l++) {
          if (this.checkedScrapList[l] == this.totalItemList[k]) {
            this.totalItemList.splice(k, 1);
          }
        }
      }
      for (var m = 0; m < this.totalItemList.length; m++) {
        var uncheckedIndex = this.totalItemList[m];
        this.tableItems.items[uncheckedIndex].fromDate = '';
        this.tableItems.items[uncheckedIndex].toDate = '';
        this.tableItems.items[uncheckedIndex].scrapCnt = '';
        this.tableItems.items[uncheckedIndex].result = null;
      }
    },
    //  체크 박스가 필요한 스크랩 그룹인지 판단
    isScrapGroupNeedCheckBoxYn() {
      return this.isScrapGroupNeedCheckBox.includes(this.scrapGroup);
    },
    isNeedResNoScrapYn(val) {
      return this.isNeedResNoScrap.includes(val);
    },
    openGuide(){
      window.open("https://www.pharmtaxplus.com/static/guide/guideTriggerScraping.html");
    },
    //========== Store 관련 메서드 . ==========
    ...mapMutations({
      setScrapHometaxPeriodStandard: 'SET_SCRAP_HOMETAX_PERIOD_STANDARD',
      setScrapCreditPeriodStandard: 'SET_SCRAP_CREDIT_PERIOD_STANDARD',
      setScrapNhisPeriodStandard: 'SET_SCRAP_NHIS_PERIOD_STANDARD',
      setScrapNpsPeriodStandard: 'SET_SCRAP_NPS_PERIOD_STANDARD',
      setScrapMediPeriodStandard: 'SET_SCRAP_MEDI_PERIOD_STANDARD',
      setScrapCardTerminalPeriodStandard: 'SET_SCRAP_CARD_TERMINAL_PERIOD_STANDARD',
      setScrapDefaultPeriodStandard: 'SET_SCRAP_DEFAULT_PERIOD_STANDARD',
    }),
  },
  computed: {
    ...mapState([
      'isScrapGroupNeedCheckBox', 'user', 'auth', 'scrapPeriodStandard', 'isNeedResNoScrap', 'taxItemList'
    ]),  //, 'period_from_to', 'hometax_year_quarter', 'hometax_year'

    // 현재 scrapGroup이 체크박스 사용하는지 판단
    isCompanyOrAgentType() {
      //  세무대리인용 or 비세무대리인용 화면 구분값
      return this.user.userInfo.COMPANY_OR_AGENT_FOR_VIEW;
    },
    // isUser() {
    //   return this.auth.userRole.ROLE.includes('USER')
    // },
    // isAgent() {
    //   return this.auth.userRole.ROLE.includes('AGENT')
    // },
    allSelected: {
      get() {
        // row count가 0이 아니고, 선택된값이 row count 값과 같으면 true
        return this.tableItems.items.length != 0 && this.checkedScrapList.length === this.tableItems.items.length;
      },
      set(value) {
        if (value) {
          for (var i = 0; i < this.tableItems.items.length; i++) {
            this.checkedScrapList.push(i);
          }
        } else {
          this.checkedScrapList = [];
        }
      }
    },
  },
  watch: {
    scrapGroup(val) {
      this.getScrap(val);
      //  테이블 items 세팅
      if (val == 'HOMETAX') {
        this.tableItems = this.hometaxTableItems;

      } else if (val == 'CREDIT') {
        this.tableItems = this.creditTableItems;
        //	조회 기준 변경
        this.setScrapCreditPeriodStandard();

      } else if (val == 'NHIS') {
        this.tableItems = this.nhisTableItems;
        //  조회 기준 변경
        this.setScrapNhisPeriodStandard();
      } else if (val == 'NPS') {
        this.tableItems = this.npsTableItems;
        //  조회 기준 변경
        this.setScrapNpsPeriodStandard();

      } else if (val == 'CARD_TERMINAL') {
        this.tableItems = this.cardTerminalTableItems;
        this.setScrapCardTerminalPeriodStandard();
      } else {
        if (val == 'CARD') {
          this.tableItems = this.cardTableItems;
        } else if (val == 'BANK') {
          this.tableItems = this.bankTableItems;
        } else if (val == 'CREDIT') {
          this.tableItems = this.creditTableItems;
        } else if (val == 'HIRA') {
          this.tableItems = this.hiraTableItems;
        } else {
          this.tableItems = this.mediTableItems;
        }
        //  조회 기준 변경 : default_from_to_ymd
        this.setScrapDefaultPeriodStandard();
      }
    },
    scrapId(val) {
      if (this.scrapGroup == "HOMETAX") {
        //  조회 기준 변경 (자동수집 내역별로 돌리는 경우라서.)
        this.setScrapHometaxPeriodStandard(val);

        if ((this.isCompanyOrAgentType == 'AGENT')) {
          // 세무대리인 수임사 목록 재조회
          if (this.agentVisible == true) {
            this.getAgentMngCorpList(val);
          }

          //  세무대리인 세금신고결과조회 시 세목 selectBox visible
          if (val == 'HOMETAX_DECLARE_RESULT') {
            this.isNeedTaxItem = true;
          } else {
            this.isNeedTaxItem = false;
          }
        }
        //  세무대리인 세금신고결과조회 시 세목 selectBox visible
        // if ((this.isCompanyOrAgentType == 'AGENT') && ) {
        //   this.isNeedTaxItem = true;
        // } else {
        //   this.isNeedTaxItem = false;
        // }
      } else if (this.scrapGroup == 'MEDI') {
        this.setScrapMediPeriodStandard(val);
      }
    },
    currPage() {
      this.getSchdManualListWithPagination(false);
    },
    yearStandard(val) {
      if (val == '') {
        this.yearItems = [
          {key: '', value: '연도 선택', selected: true}
        ];
        this.calYearList();
      }
    },
    quarterStandard(val) {
      if (val == '') {
        this.quarterItems = [
          {key: '', value: '분기 선택', selected: true},
          {key: '1', value: '1분기', selected: false},
          {key: '2', value: '2분기', selected: false},
          {key: '3', value: '3분기', selected: false},
          {key: '4', value: '4분기', selected: false}
        ];
      }
    },
    vatQuarterStandard(val) {
      if (val == '') {
        this.vatItems = [
          {key: '', value: '기수 및 예정/확정', selected: true},
          {key: '1', value: '1기 예정', selected: false},
          {key: '2', value: '1기 예정', selected: false},
          {key: '3', value: '1기 예정+확정', selected: false},
          {key: '4', value: '2기 예정', selected: false},
          {key: '5', value: '2기 예정', selected: false},
          {key: '6', value: '2기 예정+확정', selected: false},
        ];
      }
    },
  },
  mounted() {
    //연도 계산
    this.calYearList();

    // 스크랩핑 그룹 조회 메서드 호출
    this.getScrapGroup();
  },
}
</script>

<style>
.warn-box {
  text-align: center;
}

.warn-middle-box {
  position: relative;
  display: inline-block;
  background-color: #070a17;
  padding: 5px 50px;
  border-radius: 3px;
}

.warn-text {
  color: #FFFFFF;
  padding: 10px;
  /*margin-bottom: 10px;*/
}
</style>